<template>
  <div>
    <!-- Main layout structure using Quasar components -->
    <q-layout view="hHh lpr fff">
      <!-- Main content area -->
      <q-page-container>
        <router-view></router-view>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
// Export the Vue.js component
export default {
  name: "empty-layout",
};
</script>
